import axios, { AxiosRequestConfig } from 'axios'
import { IQuizResult, TAxiosResponseData } from 'src/interfaces'
import { EOpenAIMessageRole } from 'src/interfaces/open-ai'

export class AIApi {
  static readonly _prefix = '/ai'

  static messaging(payload: {
    messages: {
      role: EOpenAIMessageRole
      content: string
    }[]
  }): TAxiosResponseData<{
    id: string
    role: EOpenAIMessageRole
    content: string
  }> {
    return axios.post(`${AIApi._prefix}/messaging`, payload)
  }

  static prepareVideosData(
    payload: { videoIds: number[] },
    config?: AxiosRequestConfig
  ) {
    return axios.post<Array<{
      videoId: number
      transcription?: string
      resume?: string
      quizResult?: IQuizResult
    }>>(`${AIApi._prefix}/prepare-videos-data`, payload, config)
  }
}
