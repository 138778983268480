import { Alert, Badge, Button, Divider, Input } from 'antd'
import { AxiosError } from 'axios'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AIApi, CandidateApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { ignore } from 'src/constants'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { EOpenAIMessageRole, IOpenAIMessage, IUserModel, IVideoModel } from 'src/interfaces'
import { OpenAIService } from 'src/services'
import { AxiosUtils, NotifyUtils } from 'src/utils'
import { ModalMessages } from '../modal-messages'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof ModalFullscreen>, 'onOk' | 'afterClose'> {
  videos?: IVideoModel[]
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}

export const ModalAnalyzeCGPTVideos: FC<IProps> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const videos = useMemo(() => props.videos || [], [props.videos])
  const [open, setOpen] = useState(false)
  const [opened, setOpened] = useState(false)
  const afterCloseRef = useAsRef(props.afterClose)

  useEffect(() => {
    if (!open) {
      afterCloseRef.current?.()
    } else {
      setOpened(true)
    }
  }, [afterCloseRef, open])

  const [loading, setLoading] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [result, setResult] = useState<{ [key: string]: IOpenAIMessage[] }>({})

  const ensureWorkingExp = useCallback(async (videos: IVideoModel[]) => {
    const ids: number[] = []
    for (const { userIdAuthor, author } of videos) {
      if (userIdAuthor && !author?.linkedinWorkingExperiences) {
        ids.push(userIdAuthor)
      }
    }

    if (!ids?.length) {
      return videos
    }

    const data = await CandidateApi.getExperiencesByIds({ ids })
    for (const [id, exp] of Object.entries(data)) {
      const video = videos.find((video) => Number(video.userIdAuthor) === Number(id))
      if (video) {
        video.author = {
          ...video.author,
          linkedinWorkingExperiences: exp
        } as IUserModel
      }
    }

    return videos
  }, [])

  const onSubmit = useCallback(() => {
    setResult({})

    const promise = (async () => {
      let _videos = videos
      if (prompt.includes('[WORK_EXP]') || prompt.includes('[WORK_EXP_JSON]')) {
        _videos = await ensureWorkingExp(_videos)
      }

      const model = await OpenAIService.getModels().then((models) => models[0].id)
      const used = {
        transcription: prompt.includes('[TRANSCRIPTION]'),
        workExp: prompt.includes('[WORK_EXP]') || prompt.includes('[WORK_EXP_JSON]')
      }

      for (const video of _videos) {
        const isIgnore = (!used.transcription && !used.workExp) ||
                         (!used.transcription && !video.author?.linkedinWorkingExperiences?.length) ||
                         (!used.workExp && !video.videoTranscription?.content)
        if (isIgnore) {
          continue
        }

        const workExps = (video.author?.linkedinWorkingExperiences || []).map((exp) => ({
          title: exp.title,
          industry: exp.industry,
          skills: exp.skills,
          description: exp.description
        }))
        const workExpsTxt = workExps
          .reduce<string[]>((acc, cur) => [
            ...acc,
            [
              cur.title && `Title: ${cur.title}`,
              cur.industry && `Industry: ${cur.industry}`,
              cur.skills?.length && `Skills: ${cur.skills.join(', ')}`,
              cur.description && `Description: ${cur.description}`
            ].filter(Boolean).join('\n')
          ], [])
          .join('\n----------------\n')
        const workExpsJson = JSON.stringify(workExps)

        const content = prompt
          .replace('[TRANSCRIPTION]', video.videoTranscription?.content || '')
          .replace('[WORK_EXP]', workExpsTxt || '')
          .replace('[WORK_EXP_JSON]', '```json\n' + workExpsJson + '\n```')

        const payload = {
          model,
          messages: [{
            id: 0,
            role: EOpenAIMessageRole.USER,
            content
          }]
        }

        await AIApi.messaging(payload)
          .then(({ data }) => setResult((prev) => ({
            ...prev,
            [video.id]: [
              ...payload.messages,
              {
                id: 1,
                role: EOpenAIMessageRole.ASSISTANT,
                content: data.content
              }
            ]
          })))
          .catch((error: AxiosError) => {
            setResult((prev) => ({
              ...prev,
              [video.id]: [
                ...payload.messages,
                {
                  id: 1,
                  role: EOpenAIMessageRole.ASSISTANT,
                  content: `<span style="color: red;">${AxiosUtils.getApiErrorMessage(error)}</span>`
                }
              ]
            }))
          })
      }
    })()

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(ignore)
  }, [unsubscribe$, prompt, ensureWorkingExp, videos])

  return (
    <>
      <span className={Style.modalAnalyzeCGPTVideos}>
        <Button
          type="primary"
          {...btnProps}
          disabled={btnProps?.disabled || !videos.length}
          onClick={() => setOpen(true)}
        >
          {props.children || 'Try CGPT Prompt'}
        </Button>
        <Badge
          showZero
          count={videos.length}
        />
      </span>

      <ModalFullscreen
        title="Try Feed Chat GPT"
        // wrapClassName={Style.modalAnalyzeCGPTVideos}
        open={open}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: !prompt || loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Submit"
        cancelText="Close"
        onOk={onSubmit}
        onCancel={() => setOpen(false)}
      >
        {opened && (
          <div className="fx fx-column gap-3">
            <div className="fx fx-column gap-2">
              <Input.TextArea
                // showCount
                // maxLength={500}
                style={{ height: 120, resize: 'none' }}
                placeholder="CGPT Prompt..."
                disabled={loading}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />

              <Alert
                type="info"
                message={(
                  <>
                    <div>Use [TRANSCRIPTION] then scripts will replace with video transcription</div>
                    <div>Use [WORK_EXP] then scripts will replace with candidate work experiences</div>
                    <div>Use [WORK_EXP_JSON] then scripts will replace with candidate work experiences as JSON format</div>
                  </>
                )}
              />
            </div>

            <div className="fx fx-column gap-2">
              {videos.map((video) => (
                <div key={video.id}>
                  <Divider/>
                  <div className="fx gap-2">
                    <div className="fx-1">{video.id}</div>
                    <div className="fx-1">{!video.videoTranscription?.content ? 'No Transcription' : 'Have transcription'}</div>
                    <div className="fx-1 fx fx-jc-flex-end">
                      {result[video.id]?.length
                        ? <ModalMessages messages={result[video.id] || []}/>
                        : loading
                          ? 'Loading...'
                          : 'No data to feed'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ModalFullscreen>
    </>
  )
}
